/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.farme__multi-value__label { color:  var(--vz-white) !important;}

/* Estilos para el contenedor de sugerencias */
.pac-container {
    background-color: #fff;
    position: absolute !important;
    z-index: 10000 !important;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 2px;
  }
  
  /* Estilos para cada sugerencia */
  .pac-item {
    cursor: pointer;
    padding: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;
    color: #515151;
  }
  
  /* Hover en las sugerencias */
  .pac-item:hover {
    background-color: #f8f9fa;
  }
  
  /* Sugerencia seleccionada */
  .pac-item-selected {
    background-color: #e9ecef;
  }
  
  /* Asegurar que el modal no tape las sugerencias */
  .modal {
    z-index: 1050 !important;
  }
  
  .modal-backdrop {
    z-index: 1040 !important;
  }

  // Agregar estos estilos en tu archivo SCSS
.search-box {
  position: relative;
  
  .search {
    padding-right: 30px;
    min-width: 250px;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #74788d;
  }
}

.form-select {
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}

.btn-primary {
  .ri-filter-line {
    font-size: 16px;
    vertical-align: middle;
  }
}

//LISTA MOTORIZADO
.solicitud-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.08);
  overflow: hidden;
  margin-bottom: 16px;

  .solicitud-header {
    padding: 16px;
    background: white;

    .header-content {
      .order-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .order-number {
          font-size: 1.1rem;
          font-weight: 600;
          color: #1a1a1a;
        }
      }

      .delivery-time {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #666;
        font-size: 0.9rem;

        i {
          color: #666;
          font-size: 1.1rem;
        }
      }
    }
  }

  .status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;

    &.pendiente {
      background: #FFF5E5;
      color: #FF9500;
    }

    &.entregado {
      background: #E8F5E9;
      color: #4CAF50;
    }
  }

  .solicitud-body {
    padding: 16px;

    .customer-section {
      margin-bottom: 20px;

      .customer-info {
        display: flex;
        align-items: center;
        gap: 12px;

        .avatar {
          width: 48px;
          height: 48px;
          background: #F0F2F5;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 24px;
            color: #666;
          }
        }

        .details {
          h4 {
            margin: 0;
            font-size: 1rem;
            font-weight: 600;
            color: #1a1a1a;
          }

          p {
            margin: 4px 0 0;
            font-size: 0.9rem;
            color: #666;
          }
        }
      }
    }

    .address-section {
      display: flex;
      gap: 12px;
      margin-bottom: 20px;

      .location-icon {
        width: 48px;
        height: 48px;
        background: #F0F2F5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 24px;
          color: #666;
        }
      }

      .address-details {
        flex: 1;

        .direction-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;
          color: #1a1a1a;
          padding: 8px 0;

          .address-text {
            font-size: 0.95rem;
            color: #1a1a1a;
            flex: 1;
          }

          i {
            color: #666;
            font-size: 1.2rem;
          }
        }
      }
    }

    .delivery-info {
      .distance {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #666;
        font-size: 0.9rem;

        i {
          font-size: 1.1rem;
        }
      }
    }
  }

  .solicitud-footer {
    padding: 16px;
    border-top: 1px solid #f0f2f5;

    .btn-confirm {
      width: 100%;
      padding: 14px;
      background: #007AFF;
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background: darken(#007AFF, 5%);
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
}

// Mejoras para móviles
@media (max-width: 768px) {
  .solicitud-card {
    margin: 10px;
    border-radius: 12px;

    .solicitud-body {
      .customer-info,
      .address-section,
      .direction-link {
        min-height: 44px; // Mejor área táctil
      }

      .avatar,
      .location-icon {
        width: 40px;
        height: 40px;

        i {
          font-size: 20px;
        }
      }

      .details {
        h4 {
          font-size: 0.95rem;
        }

        p {
          font-size: 0.85rem;
        }
      }

      .address-text {
        font-size: 0.9rem;
        line-height: 1.4;
      }
    }

    .solicitud-footer {
      .btn-confirm {
        padding: 12px;
        font-size: 0.95rem;
      }
    }
  }
}

// Contenedor principal de las cards
.solicitudes-grid {
  display: grid;
  gap: 20px;
  padding: 16px;
  background-color: #F8F9FA;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

// Estilos para el botón de ruta óptima
.actions-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);

  .btn-success {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 12px;
    background: #405189;
    border: none;
    color: white;
    font-weight: 500;
    transition: all 0.2s;

    i {
      font-size: 1.2rem;
    }

    &:hover {
      background: darken(#34C759, 5%);
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

// Estilos para el modal
.mobile-friendly-modal {
  .modal-content {
    border-radius: 16px;
    overflow: hidden;
  }

  .modal-header {
    background: white;
    border-bottom: 1px solid #f0f2f5;
    padding: 16px;

    .modal-title {
      font-size: 1.1rem;
      font-weight: 600;
      color: #1a1a1a;
      margin: 0;
    }

    .close {
      font-size: 1.5rem;
      color: #666;
    }
  }

  .modal-body {
    padding: 16px;

    .delivery-info {
      background: #F8F9FA;
      padding: 16px;
      border-radius: 12px;
      margin-bottom: 20px;

      .info-item {
        margin-bottom: 8px;
        font-size: 0.95rem;

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          color: #1a1a1a;
          font-weight: 600;
        }
      }
    }

    .form-group {
      label {
        font-size: 0.95rem;
        font-weight: 500;
        color: #1a1a1a;
        margin-bottom: 8px;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid #f0f2f5;
    padding: 16px;

    .btn {
      flex: 1;
      padding: 12px;
      font-size: 0.95rem;
      font-weight: 500;
      border-radius: 12px;
      
      &.btn-secondary {
        background: #F0F2F5;
        border: none;
        color: #1a1a1a;

        &:hover {
          background: darken(#F0F2F5, 5%);
        }
      }

      &.btn-primary {
        background: #007AFF;
        border: none;

        &:hover {
          background: darken(#007AFF, 5%);
        }

        &:disabled {
          background: lighten(#007AFF, 20%);
        }

        .mdi-loading {
          animation: spin 1s linear infinite;
        }
      }
    }
  }
}

// Animación de carga
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Mejoras para la accesibilidad y estados activos
.btn,
.direction-link {
  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,122,255,0.3);
  }
}

// Soporte para modo oscuro
@media (prefers-color-scheme: dark) {
  .solicitud-card {
    background: #e9ebec;

    .solicitud-header {
      background: #e9ebec;
    }

    .order-number {
      color: white;
    }

    .avatar,
    .location-icon {
      background: #2C2C2E;
    }

    .details {
      h4 {
        color: white;
      }

      p {
        color: #8E8E93;
      }
    }

    .address-text {
      color: white;
    }
  }
}

//Estilos para el filtro de motorizados
.filtro-container {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.flatpickr-input {
  background-color: white !important;
}

.filter-section {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}


.btn-cancel {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel:hover {
  background-color: #c82333;
}

.solicitud-footer .d-flex {
  gap: 10px;
}

.ruta-actions {
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.ruta-actions .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
}

.ruta-actions .btn i {
  font-size: 1.25rem;
}

.order-number {
  font-weight: bold;
  color: #0d6efd;
  margin-right: 10px;
}

.delivery-info {
  display: flex;
  gap: 15px;
  padding: 8px 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-top: 10px;
}

.distance, .time {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  color: #6c757d;
}

.ruta-resumen {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.ruta-header h4 {
  font-size: 1.1rem;
  color: #495057;
}

.progress {
  background-color: #e9ecef;
  border-radius: 5px;
}

.progress-text {
  font-size: 0.875rem;
  color: #6c757d;
}

.stat-item {
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stat-icon {
  font-size: 1.5rem;
  color: #0d6efd;
  width: 40px;
  text-align: center;
}

.stat-label {
  font-size: 0.75rem;
  color: #6c757d;
  margin-bottom: 2px;
}

.stat-value {
  font-size: 1rem;
  color: #212529;
  display: block;
}

@media (max-width: 767.98px) {
  .ruta-resumen {
    padding: 12px;
  }
  
  .stat-item {
    padding: 8px;
  }
  
  .stat-icon {
    font-size: 1.25rem;
    width: 30px;
  }
}


/* Add this to your CSS */
.filepond--root {
  /* Ensure proper focus visibility */
  &:focus-within {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }
}

.filepond--drop-label {
  /* Remove aria-hidden styling if applied */
  &[aria-hidden] {
    display: block !important;
    visibility: visible !important;
  }
}

/* Ensure proper focus styles for interactive elements */
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 2px #007bff;
}

/* MODAL POLITICAS */
.policy-content {
  padding: 20px;
}

.policy-content h5 {
  color: #2f4f4f;
  font-weight: 600;
}

.policy-content p {
  color: #4a4a4a;
  line-height: 1.6;
}

.policy-list {
  padding-left: 20px;
}

.policy-list li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #4a4a4a;
}

/* BOTON IMPRIMIR ETIQUETA */
// Estilos para la etiqueta
.solicitud-label {
  background: white;
  width: 100%;
  height: 100%;
  page-break-inside: avoid;
}





/* Estilos específicos para impresión */


/* 2 */
/* Estilos para la etiqueta */
.solicitud-label {
  background: white;
  page-break-inside: avoid;
}

/* Estilos específicos para impresión */
@media print {
  @page {
    size: 80mm auto; /* Ancho fijo, alto automático */
    margin: 0;
  }

  body * {
    visibility: hidden;
  }

  .solicitud-label,
  .solicitud-label * {
    visibility: visible !important;
  }

  .solicitud-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 80mm !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Asegurar que las imágenes y QR se impriman */
  .solicitud-label img,
  .solicitud-label svg {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

/* Informacion de Salud */
.text-break-all {
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
}